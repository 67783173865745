import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Switch, { SwitchProps } from '@mui/material/Switch';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { styled } from '@mui/material/styles';
import colors from "../constant/Colors";
import { useHistory, useParams } from "react-router-dom";

export default function FilterMenuContent({
  filterA,
  filterC,
  filterD,
  setCardFilter,
  filter1,
  setFilter1,
  filter2,
  setFilter2,
  filter3,
  setFilter3,
  rudeCardFilter,
  setRudeCardFilter,
  menuClose,
  items,
  type,
  filterOccasion,
  setFilterOccasion
}) {
  const [expandedAccordionA, setExpandedAccordionA] = useState(false);
  const [expandedAccordionB, setExpandedAccordionB] = useState(false);
  const [expandedAccordionC, setExpandedAccordionC] = useState(false);
  const [expandedAccordionD, setExpandedAccordionD] = useState(false);
  const [expandedAccordionE, setExpandedAccordionE] = useState(false);

  const [selectedFilterA, setSelectedFilterA] = useState(filter1);
  const [selectedFilterC, setSelectedFilterC] = useState(filter2);
  const [selectedFilterD, setSelectedFilterD] = useState(filter3);
  const history = useHistory();
  // Use useEffect to update the cardFilter whenever any selected filter changes
  useEffect(() => {
    setCardFilter([selectedFilterA, selectedFilterC, selectedFilterD].filter(Boolean));
  }, [selectedFilterA, selectedFilterC, selectedFilterD, setCardFilter]);

  const clickHandler = (group, value) => {
    switch (group) {
      case 'A':
        setSelectedFilterA(selectedFilterA === value ? null : value);
        setFilter1(selectedFilterA === value ? null : value);
        break;
      case 'C':
        setSelectedFilterC(selectedFilterC === value ? null : value);
        setFilter2(selectedFilterC === value ? null : value);
        break;
      case 'D':
        setSelectedFilterD(selectedFilterD === value ? null : value);
        setFilter3(selectedFilterD === value ? null : value);
        break;
      default:
        break;
    }
  };

  const handleClick = (occasion) => {
    setFilterOccasion(occasion);
    if (type === "themes") {
      history.push(`/product/${occasion}/1`);
    } else if (type === "gifts") {
      history.push(`/gift/${occasion}/1`);
    } else if (type === "addcard") {
      history.push(`/addcard/${occasion}/1`);
    }
  };

  const filterRudeCards = (e) => {
    console.log('=====', e.target.checked);
    setRudeCardFilter(e.target.checked);
  };

  const handleAccordionChange = (accordion) => () => {
    switch (accordion) {
      case "A":
        setExpandedAccordionA(!expandedAccordionA);
        break;
      case "B":
        setExpandedAccordionB(!expandedAccordionB);
        break;
      case "C":
        setExpandedAccordionC(!expandedAccordionC);
        break;
      case "D":
        setExpandedAccordionD(!expandedAccordionD);
        break;
      case "E":
        setExpandedAccordionE(!expandedAccordionE);
        break;
      default:
        break;
    }
  };

  const FilterAccordion = ({ title, group, accordion, selectedFilter }) => {
    const isExpanded =
      accordion === "A"
        ? expandedAccordionA
        : accordion === "B"
        ? expandedAccordionB
        : accordion === "C"
        ? expandedAccordionC
        : expandedAccordionD;

    return (
      <Accordion
        expanded={isExpanded}
        disableGutters={true}
        onChange={handleAccordionChange(accordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography variant="body1">
            <strong style={{ fontFamily: "Arial" }}>{title}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {group.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item}
                checked={selectedFilter === item}
                control={<Checkbox />}
                onClick={() => clickHandler(accordion, item)}
                label={
                  <Typography
                    sx={{
                      fontFamily: "Moonpig",
                    }}
                  >
                    {item}
                  </Typography>
                }
                sx={{ margin: 0 }}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  };

  const FilterAccordionOccasion = ({ title, group, accordion, selectedFilter }) => {
    const isExpanded =
      accordion === "A"
        ? expandedAccordionA
        : accordion === "B"
        ? expandedAccordionB
        : accordion === "C"
        ? expandedAccordionC
        : accordion === "D"
        ? expandedAccordionD
        : expandedAccordionE;

    return (
      <Accordion
        expanded={isExpanded}
        disableGutters={true}
        onChange={handleAccordionChange(accordion)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography variant="body1">
            <strong style={{ fontFamily: "Arial" }}>{title}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {group.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item}
                checked={selectedFilter==item}
                control={<></>} // Remove the checkbox
                onClick={() => handleClick(item)}
                label={
                  <Typography
                    sx={{
                      fontFamily: "Moonpig",
                    }}
                  >
                    {item}
                  </Typography>
                }
                sx={{ margin: 1 }}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch onChange={(e) => filterRudeCards(e)} checked={rudeCardFilter} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#236dcc',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#236dcc',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <Box sx={{ width: "250px", height: "50vh" }}>
      <FilterAccordionOccasion
        title="Occasion"
        group={items.map(item => item.name)}
        accordion="E"
        selectedFilter={filterOccasion}
      />
      <FilterAccordion
        title="Who's it for"
        group={filterA.flat()}
        accordion="A"
        selectedFilter={selectedFilterA}
      />

      <FilterAccordion
        title="Age Milestones"
        group={filterC}
        accordion="C"
        selectedFilter={selectedFilterC}
      />

      <FilterAccordion
        title="Card Theme"
        group={filterD}
        accordion="D"
        selectedFilter={selectedFilterD}
      />

      <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={true}/>}
        label=" Rude Cards"
        style={{marginLeft: '5px'}}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{
          padding: 1,
          position:
            expandedAccordionA ||
            expandedAccordionB ||
            expandedAccordionC ||
            expandedAccordionD ||
            expandedAccordionE
              ? "sticky"
              : "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          borderTop: "1px solid",
          backgroundColor: colors.greyback,
        }}
        onClick={() => {
          setSelectedFilterA(null);
          setSelectedFilterC(null);
          setSelectedFilterD(null);
          setFilter1(null);
          setFilter2(null);
          setFilter3(null);
          setCardFilter([]);
          menuClose();
        }}
      >
        <Button variant="text" color="inherit">
          <Typography variant="body1">
            <strong style={{ fontFamily: "Arial" }}>
              <span>Close Filters</span>
            </strong>
          </Typography>
          <CloseSharpIcon />
        </Button>
      </Stack>
    </Box>
  );
}
