import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Container,
  Stack,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import { logout } from "../../store/actions/userActions";

export default function DrawerContent({ toggle, userInfo }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleButtonClick = (event, item) => {
    event.preventDefault();
    history.push(item.url);
    toggle(event);
  };
  const items = [
    {
      name: "All Cards",
      url: "/products/page/1",
      icon: <ChevronRightIcon />,
    },
    {
      name: "Birthday Cards",
      url: "/product/Birthday/1",
      icon: <ChevronRightIcon />,
    },
    {
      name: "Thank You Cards",
      url: "/product/Thank%20You/1",
      icon: <ChevronRightIcon />,
    },
    {
      name: "Kids Cards",
      url: "/product_spec/Kids/1",
      icon: <ChevronRightIcon />,
    },
    {
      name: "Celebrity Cards",
      url: "/product_spec/Celebrities/1",
      icon: <ChevronRightIcon />,
    },
    {
      name: "Gifts / Giftcards",
      url: "/gifts/page/1",
      icon: <ChevronRightIcon />,
    },
  ];

  if (userInfo) {
    if (userInfo.isAdmin) {
      items.push({
        name: "Admin",
        url: "/admin/dashboard",
        icon: <ChevronRightIcon />,
        // icon: <VerifiedUserOutlinedIcon />,
      });
    } else {
      items.push({
        name: "My account",
        url: "/account/profile",
        icon: <ChevronRightIcon />,
        // icon: <AccountCircleOutlinedIcon />,
      });
    }
  }

  const loginHandler = (event) => {
    history.push("/login");
    toggle(event);
  };

  const logoutHandler = (event) => {
    dispatch(logout());
    history.push("/");
    toggle(event);
  };

  const toSearch = (event) => {
    history.push("/search");
    toggle(event);
  };

  return (
    <Container sx={{ height: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingY: 2 }}
      >
        <Link href="/" underline="none" color="inherit">
          {" "}
          <img
            id="logo"
            alt="Logo"
            src="/assets/logo.jpg"
            className="block"
            style={{ height: "35px" }}
          />
        </Link>
        <IconButton onClick={(e) => toggle(e)} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Divider />

      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          {items.map((item, i) => (
            <div key={i}>
              <ListItemButton
                onClick={(e) => handleButtonClick(e, item)}
                sx={{ paddingX: 0, paddingY: 1 }}
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    variant: "body1",
                    sx: { fontWeight: "bold" },
                  }}
                />
                <ListItemIcon sx={{ minWidth: "0px" }}>
                  {item.icon}
                </ListItemIcon>
              </ListItemButton>
              <Divider />
            </div>
          ))}

          {!userInfo ? (
            <ListItemButton
              onClick={loginHandler}
              sx={{ paddingX: 0, paddingY: 1 }}
            >
              <ListItemText
                primary="Sign-in"
                primaryTypographyProps={{
                  variant: "body1",
                  sx: { fontWeight: "bold" },
                }}
              />
              <ListItemIcon sx={{ minWidth: "0px" }}>
                <PersonIcon />
              </ListItemIcon>
            </ListItemButton>
          ) : (
            <ListItemButton
              onClick={logoutHandler}
              sx={{ paddingX: 0, paddingY: 1 }}
            >
              <ListItemText
                primary="Log out"
                primaryTypographyProps={{
                  variant: "body1",
                  sx: { fontWeight: "bold" },
                }}
              />
              <ListItemIcon sx={{ minWidth: "0px" }}>
                <LogoutRoundedIcon />
                {/* <ChevronRightIcon /> */}
              </ListItemIcon>
            </ListItemButton>
          )}
          <Divider />
          <ListItemButton onClick={toSearch} sx={{ paddingX: 0, paddingY: 1 }}>
            <Button
              variant="outlined"
              fullWidth
              color="inherit"
              size="large"
              sx={{ borderRadius: 10, marginTop: 1 }}
            >
              <Typography variant="body1">
                <span>Search for Cards.....</span>
              </Typography>
            </Button>
          </ListItemButton>
        </ListItem>
      </List>
    </Container>
  );
}
