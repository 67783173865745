import React, { useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Box, Stack, Menu, MenuItem, useMediaQuery, IconButton } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SortIcon from "@mui/icons-material/Sort";
import {
  filterA,
  filterC,
  filterD,
} from "../constant/CardFilterItems";
import FilterMenuContent from "./FilterMenuContent";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const GroupFilter = (props) => {
  const history = useHistory();
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = direction === "left" ? -300 : 300;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const { items, type, cardFilter, setCardFilter, rudeCardFilter, setRudeCardFilter, setSortFilter } = props;
  
  const { occasion, genres } = useParams();
  const [sortType, setSortType] = useState(1);
  const [filter1, setFilter1] = useState('');
  const [filter2, setFilter2] = useState('');
  const [filter3, setFilter3] = useState('');
  const [filterOccasion, setFilterOccasion] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleClick = (occasion) => {
    if (type === "themes") {
      history.push(`/product/${occasion}/1`);
    } else if (type === "gifts") {
      history.push(`/gift/${occasion}/1`);
    } else if (type === "addcard") {
      history.push(`/addcard/${occasion}/1`);
    }
    setFilterOccasion(occasion);
  };

  const handleAll = () => {
    if (type === "themes") {
      history.push(`/products/page/1`);
    } else if (type === "gifts") {
      history.push(`/gifts/page/1`);
    } else if (type === "addcard") {
      history.push("/addcards/page/1");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorElSort, setAnchorElSort] = useState(null);
  const openSort = Boolean(anchorElSort);

  const menuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuOpenSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  const menuCloseSort = () => {
    setAnchorElSort(null);
  };

  const handleSortSelection = (id) => {
    setSortFilter(id);
    setSortType(id);
    menuCloseSort();
  };

  const buttonStyles = {
    borderRadius: "10px",
    paddingX: "20px",
    minWidth: 'auto', // Allow auto width on mobile
    whiteSpace: 'nowrap', // Prevent text wrapping
    textAlign: 'center', // Center text within the button
    margin: '2px'
  };

  return (
    <Box className="">
      {/* <Stack
        direction="row"
        spacing={1}
        sx={{
          flexWrap: isMobile ? 'nowrap' : 'wrap',
          // alignItems: 'center',
          // justifyContent: isMobile ? 'flex-start' : 'center', // Align left on mobile, center on desktop
          overflowX: isMobile ? 'auto' : 'visible',
          gap: '5px',
          '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar in WebKit browsers
          },
          '-ms-overflow-style': 'none', // Hide scrollbar in Internet Explorer and Edge
          'scrollbar-width': 'none', // Hide scrollbar in Firefox
        }}
      > */}
        
        <div className="scrollContainer" style={{ position: 'relative' }}>
          
          <IconButton
            aria-label="scroll left"
            onClick={() => scroll("left")}
            style={{ position: 'absolute', left: 0, top: '0', zIndex: 1 }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          
          <div
            className="filterCardPanel"
            ref={scrollRef}
            style={{
              display: 'flex',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              padding: '0 40px',
              scrollbarWidth: 'none', // For Firefox
              msOverflowStyle: 'none', // For Internet Explorer and Edge
            }}
          >
          
            {type === "themes" || type === "addcard" ? (
              <Button
                variant="outlined"
                startIcon={<TuneIcon />}
                sx={buttonStyles}
                onClick={menuOpen}
              >
                <span>Filters</span>
              </Button>
            ) : null}

            <Button
              variant="outlined"
              startIcon={<SortIcon />}
              sx={buttonStyles}
              onClick={menuOpenSort}
            >
              <span>Sort</span>
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={menuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                dense: true,
              }}
            >
              <FilterMenuContent
                filterA={filterA}
                filterC={filterC}
                filterD={filterD}
                setCardFilter={setCardFilter}
                filter1={filter1}
                setFilter1={setFilter1}
                filter2={filter2}
                setFilter2={setFilter2}
                filter3={filter3}
                setFilter3={setFilter3}
                rudeCardFilter={rudeCardFilter}
                setRudeCardFilter={setRudeCardFilter}
                menuClose={menuClose}
                items={items}
                type={type}
                filterOccasion={filterOccasion}
                setFilterOccasion={setFilterOccasion}
              />
            </Menu>

            <Menu
              id="sort-menu"
              anchorEl={anchorElSort}
              open={openSort}
              onClose={menuCloseSort}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                dense: true,
              }}
            >
              <MenuItem selected={sortType === 1} onClick={() => handleSortSelection(1)}>Newest First</MenuItem>
              <MenuItem selected={sortType === 0} onClick={() => handleSortSelection(0)}>Oldest First</MenuItem>
              <MenuItem selected={sortType === 2} onClick={() => handleSortSelection(2)}>Alphabetically</MenuItem>
            </Menu>

            {/* <Button
              variant={occasion ? "outlined" : genres ? "outlined" : "contained"}
              sx={buttonStyles}
              onClick={() => handleAll()}
            >
              <span>All</span> */}
            {/* </Button> */}
            {items.map((category, index) => (
              <Button
                key={index}
                variant={category.name === occasion ? "contained" : "outlined"}
                sx={buttonStyles}
                onClick={() => handleClick(category.name)}
              >
                <span>{category.name}</span>
              </Button>
            ))}
          </div>

          <IconButton
            aria-label="scroll right"
            onClick={() => scroll("right")}
            style={{ position: 'absolute', right: 0, top: '0', zIndex: 1 }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      {/* </Stack> */}
    </Box>
  );
};

export default GroupFilter;
